<template>
  <div class="pb-4 text-xs bg-gray-800 rounded-md scene-tree">
    <!-- <div
      class="sticky top-0 z-40 flex items-center justify-between p-2 mx-2 font-sans text-gray-200 bg-gray-800 border-b border-gray-600"
    > -->
    <!-- <div class="text-xs left-side">
        <fa-icon icon="magnifying-glass" class="text-gray-300" />
        <input
          v-model="filter"
          class="px-2 py-1 m-1 ml-2 text-xs text-gray-200 bg-gray-700 border-gray-600 rounded input-text"
          type="search"
          placeholder="Filter scene objects"
        />
        <a
          v-if="hasFilter"
          href="#"
          class="ml-1 text-gray-200 underline cursor-pointer"
          @click="filter = null"
          >Clear</a
        >
      </div> -->
    <!-- <div class="flex right-side">
        <div class="p-1 cursor-pointer"><fa-icon icon="plus" class="ml-1 text-sm text-gray-500 hover:text-white fa-fw" /></div>
        <div class="p-1 cursor-pointer"><fa-icon icon="upload" class="ml-1 text-sm text-gray-500 hover:text-white fa-fw" /></div>
      </div> -->
    <!-- </div> -->

    <div class="mx-2">
      <div class="text-xs bg-gray-800 tabs sub-tabs">
        <template v-for="tab in tabs">
          <a
            href="#"
            class="border-b-2 tab"
            :class="{
              'font-bold border-gray-700 rounded-t-sm': active === tab.type
            }"
            @click.prevent="active = tab.type"
          >
            <span class="text-gray-100">{{ tab.label }}</span>
          </a>
        </template>
      </div>

      <div class="my-2 overflow-y-scroll" style="height: 200px">
        <SceneGraphRootNode
          :key="versionKey"
          :root-node="visibleNode"
          :active-id="activeId"
          :gui="gui"
          @view="onView"
          @focus="onFocus"
          @toggle-play="onTogglePlay"
        />
      </div>

      <!-- <SceneGraphRootNode
        :key="versionKey + '-mat'"
        :root-node="visibleMaterialNode"
        :active-id="activeId"
        :gui="gui"
        @view="onView"
        @focus="onFocus"
        @toggle-play="onTogglePlay"
      /> -->

      <!-- <SceneGraphRootNode
        :key="versionKey + '-tex'"
        :root-node="visibleTextureNode"
        :active-id="activeId"
        :gui="gui"
        @view="onView"
        @focus="onFocus"
        @toggle-play="onTogglePlay"
      /> -->

      <!-- <SceneGraphRootNode
        :key="versionKey + '-animations'"
        :root-node="visibleAnimationNode"
        :active-id="activeId"
        :gui="gui"
        @view="onView"
        @focus="onFocus"
        @toggle-play="onTogglePlay"
      /> -->
      <!--
      <SceneGraphRootNode
        v-bind:key="versionKey + '-modules'"
        v-bind:root-node="visibleModulesNode"
        v-bind:active-id="activeId"
        v-bind:gui="gui"
        v-on:view="onView"
        v-on:focus="onFocus"
        v-on:toggle-play="onTogglePlay"/> -->
    </div>

    <div>
      <SceneMessaging :gui="gui" />
    </div>
  </div>
</template>
<script>
const SceneGraph = () =>
  import('@components/editor/scene/model/panels/SceneGraph')
const SceneGraphRootNode = () =>
  import('../model/panels/scenegraph/SceneGraphRootNode')
const SceneMessaging = () => import('./SceneMessaging')

export default {
  name: 'SceneTree',

  components: {
    SceneGraphRootNode,
    SceneMessaging
  },

  props: {
    viewerCore: {
      type: Object,
      required: true
    },
    activeNodeId: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      tabs: [
        { type: 'nodes', label: 'Nodes' },
        { type: 'mats', label: 'Materials' },
        { type: 'textures', label: 'Textures' }
      ],
      active: 'nodes',
      filter: null,
      filterTimeout: null,

      visibleSceneNode: null,
      visibleMaterialNode: null,
      visibleTextureNode: null,
      visibleAnimationNode: null,

      visibleModulesNode: null
    }
  },

  computed: {
    visibleNode() {
      switch (this.active) {
        case 'nodes':
          return this.visibleSceneNode
        case 'mats':
          return this.visibleMaterialNode
        case 'textures':
          return this.visibleTextureNode
      }
    },

    activeId() {
      if (this.activeNodeId === null) return null

      if (this.activeNodeId.startsWith('node-')) {
        return this.activeNodeId.replace('node-', '')
      }

      return null
    },

    scenePickedObject() {
      return this.gui.selectedObj
    },

    versionKey() {
      if (this.visibleSceneNode !== null) {
        if (this.visibleSceneNode._lastChange !== undefined)
          return this.visibleSceneNode._lastChange
      }

      return null
    },

    hasFilter() {
      return this.filter !== null && this.filter !== ''
    },

    gui() {
      return this.viewerCore.gui
    },

    scene() {
      return this.viewerCore.scene
    },

    materials() {
      return this.gui.sceneMaterials
    }
  },

  watch: {
    filter: {
      handler() {
        if (this.hasFilter) {
          clearTimeout(this.filterTimeout)
          this.filterTimeout = setTimeout(() => {
            this.visibleMaterialNode = this.gui.filteredMaterialsTree(
              this.filter
            )
            this.visibleTextureNode = this.gui.filteredTexturesTree(this.filter)
            this.visibleAnimationNode = this.gui.filteredAnimationsTree(
              this.filter
            )
            this.visibleSceneNode = this.gui.filteredSceneTree(this.filter)

            // this.visibleModulesNode = this.gui.filteredModulesTree(this.filter)
          }, 100)
        } else {
          clearTimeout(this.filterTimeout)
          this.visibleMaterialNode = this.gui.sceneMaterialsTree
          this.visibleTextureNode = this.gui.sceneTexturesTree
          this.visibleAnimationNode = this.gui.sceneAnimationsTree
          this.visibleSceneNode = this.gui.sceneNodeTree
          this.visibleModulesNode = this.gui.sceneModulesTree
        }
      },
      immediate: true
    },

    activeNode: {
      handler(node) {
        this.gui.highlightObject(node)
      }
    },

    versionKey() {
      this.visibleMaterialNode = this.gui.sceneMaterialsTree
      this.visibleTextureNode = this.gui.sceneTexturesTree
      this.visibleAnimationNode = this.gui.sceneAnimationsTree
      this.visibleModulesNode = this.gui.sceneModulesTree
    },

    scenePickedObject() {
      if (!this.scenePickedObject) return
      this.viewNode(this.scenePickedObject)
    }
  },

  methods: {
    onFocus() {},

    onTogglePlay(node) {
      if (node.name !== undefined) {
        this.gui.togglePlayAnimation(node.name)
      }
    },

    onView(node) {
      this.$bus.$emit('model:scenegraph:viewnode', node)
    },

    viewNode(node) {
      this.$bus.$emit('editor:sidebar', {
        component: SceneGraph,
        props: {
          key: 'node-' + node.uuid,
          node: node,
          gui: this.gui
        }
      })
    }
  }
}
</script>
